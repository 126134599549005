import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "productErrorContainer",
    "noError",
    "noProduct",
    "productError",
  ];

  connect() {
    if (this.productErrorTarget.checked == true) {
      this.productErrorContainerTarget.classList.remove("d-none");
    }
  }

  toggleOff() {
    this.productErrorContainerTarget.classList.add("d-none");
    this.noErrorTarget.checked = true;
    this.noProductTarget.checked = true;
  }

  toggleOn() {
    this.productErrorContainerTarget.classList.remove("d-none");
  }
}
